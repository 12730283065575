import store from '@/store'
import { CHECK_AUTH } from '@/store/actions.type'
import permissionService from '@/services/permission.service'

export default (router) => {
  router.beforeEach(async (to, from, next) => {
    // If user needs to go to "before-enter" pages, skip all other checks
    if (
      store.getters.isAuthenticated &&
      to.matched[0].path === '/before-enter'
    ) {
      return next()
    }

    await store.dispatch(CHECK_AUTH)

    // If the user is not authenticated and he is trying to access one of the protected routes, we redirect him to home page
    if (to.path.startsWith('/activate') || to.path.startsWith('/password')) {
      next()
    } else if (
      !store.getters.isAuthenticated &&
      !to.path.startsWith('/login')
    ) {
      next({ name: 'login' })
    } else if (store.getters.isAuthenticated && to.path === '/login') {
      next({ name: 'home' })
    }

    // Protect certain routes from users that don't have the right permissions
    else if (
      to.path.endsWith('reports') &&
      !permissionService.canDownloadReports()
    ) {
      next({ name: 'home' })
    } else if (
      to.path.endsWith('live-scoreboard') &&
      !permissionService.canViewLiveScoreboard()
    ) {
      next({ name: 'home' })
    } else if (
      to.path.endsWith('print-cards') &&
      !permissionService.canPrintCards()
    ) {
      next({ name: 'home' })
    } else if (
      to.path.endsWith('comparison') &&
      !permissionService.canComparePlayers()
    ) {
      next({ name: 'home' })
    }

    // All checks passed
    else {
      next()
    }
  })
}
