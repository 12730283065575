import ApiService from '@/services/api.service'
import permissionService from '@/services/permission.service'

import {
  SELECT_TEAM,
  FETCH_TEAM_BY_ID,
  FETCH_TEAMS_BY_ORG,
  FETCH_AND_STORE_TEAMS_BY_ORG,
  FETCH_ALL_TEAM_USERS,
  FETCH_PLAYERS_BY_TEAM,
  FETCH_PLAYERS_BY_TEAM_ID,
  FETCH_TRAINERS_BY_TEAM,
  ADD_TEAM,
  ADD_PLAYER_TO_TEAM,
  ADD_TRAINER_TO_TEAM,
  UPDATE_TEAM,
  UPDATE_PLAYER,
  UPDATE_TRAINER,
  DELETE_TEAM,
  ADD_USER_TO_TEAM,
  REMOVE_USER_FROM_TEAM,
  FETCH_COMPLETE_TEAM_BY_ID,
  FETCH_PLAYERS_BY_ORG,
  FETCH_TRAINERS_BY_ORG,
  FETCH_TRAINER_TEAMS,
  FETCH_TEAM_AVERAGES
} from '../actions.type'

import {
  SET_ERROR,
  SET_TEAMS,
  SET_SELECTED_TEAM,
  SET_TEAM_PLAYERS,
  SET_TEAM_TRAINERS,
  ADD_NEW_TEAM,
  SET_UPDATED_TEAM,
  REMOVE_TEAM,
  REMOVE_USER_FROM_UNASSIGNED,
  ADD_TO_PLAYERS,
  ADD_TO_TRAINERS,
  ADD_USER_TO_UNASSIGNED,
  REMOVE_FROM_TEAM_PLAYERS,
  REMOVE_FROM_TRAINERS,
  ADD_NEW_PLAYER_TO_TEAM,
  ADD_NEW_TRAINER_TO_TEAM,
  SET_UPDATED_PLAYER,
  SET_UPDATED_TRAINER,
  // REMOVE_FROM_ORG_PLAYERS,
  ADD_TO_ORG_PLAYERS,
  SET_TEAM_AVERAGES
} from '../mutations.type'

const actions = {
  async [SELECT_TEAM](context, teamId) {
    /**
     * For selecting a team in the top drop-down menu selection.
     * After a team is selected, all its players and trainers need to be
     * refetched again and saved in the Vuex store.
     */

    context.commit(SET_SELECTED_TEAM, teamId)

    if (permissionService.hasTrainerRoles()) {
      await context.dispatch(FETCH_ALL_TEAM_USERS)
    }
  },
  async [FETCH_TRAINER_TEAMS](context) {
    /**
     * Fetch a team and all its corresponding data given the ID
     */
    await ApiService.get(`/teams`)
      .then(({ data }) => {
        context.commit(SET_TEAMS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_TEAM_BY_ID](context, teamId) {
    /**
     * Fetch a team and all its corresponding data given the ID
     */
    await ApiService.get(`/teams/${teamId}`)
      .then(({ data }) => {
        context.commit(SET_TEAMS, [data.data])
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_COMPLETE_TEAM_BY_ID](context, teamId) {
    /**
     * Fetch a team and all its corresponding data given the ID
     */

    return await ApiService.get(`/teams/${teamId}/complete`)
      .then(({ data }) => {
        return data.data
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_TEAMS_BY_ORG](context, orgId) {
    /**
     * Fetch teams in the currently selected organisation and
     * return the results.
     */
    return await ApiService.get(`/organisations/${orgId}/teams`)
      .then(({ data }) => {
        return data.data
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_AND_STORE_TEAMS_BY_ORG](context) {
    /**
     * Fetch teams in the currently selected organisation and
     * save the results in Vuex store.
     */

    const orgId = context.getters.getSelectedOrganisation.id

    await ApiService.get(`/organisations/${orgId}/teams`)
      .then(({ data }) => {
        context.commit(SET_TEAMS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_ALL_TEAM_USERS](context) {
    /**
     * Fetch all users in a given team with different roles (players, trainers)
     */

    await context.dispatch(FETCH_PLAYERS_BY_TEAM)
    await context.dispatch(FETCH_TRAINERS_BY_TEAM)
  },

  async [FETCH_PLAYERS_BY_TEAM](context) {
    /**
     * Fetch players in the currently selected team and
     * return the results.
     */

    const teamId = context.getters.getSelectedTeam.id

    await ApiService.get(`/teams/${teamId}/users/players`)
      .then(({ data }) => {
        context.commit(SET_TEAM_PLAYERS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },
  async [FETCH_PLAYERS_BY_TEAM_ID](context, teamId) {
    try {
      const { data } = await ApiService.get(`/teams/${teamId}/users/players`)

      return data.data
    } catch ({ response }) {
      context.commit(SET_ERROR, response.data.message)
    }
  },

  async [FETCH_TRAINERS_BY_TEAM](context) {
    /**
     * Fetch trainers in the currently selected organisation and
     * return the results.
     */

    const teamId = context.getters.getSelectedTeam.id

    await ApiService.get(`/teams/${teamId}/users/trainers`)
      .then(({ data }) => {
        context.commit(SET_TEAM_TRAINERS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [ADD_TEAM](context, payload) {
    /**
     * Add a new team to the selected organisation
     */

    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.post(`organisations/${org_id}/teams`, payload)
      .then(({ data }) => {
        context.commit(ADD_NEW_TEAM, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [UPDATE_TEAM](context, payload) {
    /**
     * Update team attributes with the given properties.
     * After the team has been successfully updated, the teams are refetched
     * from the backend in order to have the latest version.
     */

    await ApiService.patch(`/teams/${payload.id}`, payload)
      .then(({ data }) => {
        context.commit(SET_UPDATED_TEAM, data.data)
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [DELETE_TEAM](context, payload) {
    /**
     * Delete a team
     */

    await ApiService.delete(`/teams/${payload.id}`)
      .then(() => {
        context.commit(REMOVE_TEAM, payload)
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [ADD_PLAYER_TO_TEAM](context, payload) {
    /**
     * Add a new player to the selected team
     */

    const team_id = context.getters.getSelectedTeam.id

    await ApiService.post(`teams/${team_id}/users/players`, payload)
      .then(({ data }) => {
        context.commit(ADD_NEW_PLAYER_TO_TEAM, data.data)
        if (permissionService.hasOrganizationAdminRoles()) {
          context.commit(ADD_TO_ORG_PLAYERS, data.data)
        }
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [ADD_TRAINER_TO_TEAM](context, payload) {
    /**
     * Add a new trainer to the selected team
     */

    const team_id = context.getters.getSelectedTeam.id

    await ApiService.post(`teams/${team_id}/users/trainers`, payload)
      .then(({ data }) => {
        context.commit(ADD_NEW_TRAINER_TO_TEAM, data.data)
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [UPDATE_PLAYER](context, payload) {
    /**
     * Update an existing player in the selected team
     */

    await ApiService.patch(`/users/${payload.user_id}`, payload)
      .then(({ data }) => {
        context.commit(SET_UPDATED_PLAYER, data.data)

        // TODO: Optimize
        if (permissionService.hasOrganizationAdminRoles()) {
          context.dispatch(FETCH_PLAYERS_BY_ORG)
        }
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [UPDATE_TRAINER](context, payload) {
    /**
     * Update an existing trainer in the selected team
     */

    await ApiService.patch(`/users/${payload.user_id}`, payload)
      .then(({ data }) => {
        context.commit(SET_UPDATED_TRAINER, data.data)

        // TODO: Optimize
        if (permissionService.hasOrganizationAdminRoles()) {
          context.dispatch(FETCH_TRAINERS_BY_ORG)
        }
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [ADD_USER_TO_TEAM](context, payload) {
    /**
     * Assign an existing user to the team as a player or trainer
     */

    await ApiService.post(
      `/teams/${payload.team_id}/users/${payload.user_id}`,
      payload
    )
      .then(async () => {
        let user = context.rootState.organisations.unassigned.find(
          (user) => user.id === payload.user_id
        )

        if (user == null) {
          await ApiService.get(`/users/${payload.user_id}`).then(({ data }) => {
            user = data.data
          })
        }

        context.commit(REMOVE_USER_FROM_UNASSIGNED, user)

        if (payload.is_player) {
          context.commit(ADD_TO_PLAYERS, user)
          if (permissionService.hasOrganizationAdminRoles()) {
            context.commit(ADD_TO_ORG_PLAYERS, user)
          }
        }

        if (payload.is_trainer) context.commit(ADD_TO_TRAINERS, user)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [REMOVE_USER_FROM_TEAM](context, payload) {
    /**
     * Remove a user (player / trainer) from the team
     */

    await ApiService.delete(
      `/teams/${payload.team_id}/users/${payload.user_id}`
    )
      .then(async () => {
        let user = {}

        if (payload.is_player) {
          user = await context.rootState.organisations.players.find(
            (user) => user.id === payload.user_id
          )

          context.commit(REMOVE_FROM_TEAM_PLAYERS, payload.user_id)

          // if (permissionService.hasOrganizationAdminRoles()) {
          //   context.commit(REMOVE_FROM_ORG_PLAYERS, payload.user_id)
          // }
        }
        if (payload.is_trainer) {
          user = await context.state.trainers.find(
            (user) => user.id === payload.user_id
          )
          context.commit(REMOVE_FROM_TRAINERS, user)
        }
        context.commit(ADD_USER_TO_UNASSIGNED, user)
      })
      .catch(({ response }) => {
        const errors = response.data.errors
        context.commit(SET_ERROR, errors[Object.keys(errors)[0]][0])
      })
  },

  async [FETCH_TEAM_AVERAGES](context, payload) {
    /**
     * Fetch the averages of a team
     */

    await ApiService.get(
      `/teams/${payload.teamId}/${payload.playerId}/skillscore-history`
    )
      .then(({ data }) => {
        context.commit(SET_TEAM_AVERAGES, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
