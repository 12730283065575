import { UPDATE_SELECTED_PLAYERS_FOR_PRINT } from '../actions.type'
import { SET_SELECTED_PLAYERS_FOR_PRINT } from '../mutations.type'
import { UPDATE_ENABLED_BLEED_DOTS } from '../actions.type'
import { SET_ENABLED_BLEED_DOTS } from '../mutations.type'
import { FETCH_IMAGE_WITHOUT_CORS } from '../actions.type'
import { SET_IMAGE_WITHOUT_CORS } from '../mutations.type'
import { UPDATE_PRINTING_ACTIVE_COLOR } from '../actions.type'
import { SET_PRINTING_ACTIVE_COLOR } from '../mutations.type'
import { UPDATE_PRINTING_MAIN_COLOR } from '../actions.type'
import { SET_PRINTING_MAIN_COLOR } from '../mutations.type'
import { UPDATE_PRINTING_LOGO } from '../actions.type'
import { SET_PRINTING_LOGO_IMAGE } from '../mutations.type'
import { UPDATE_PRINTING_SECONDARY_COLOR } from '../actions.type'
import { SET_PRINTING_SECONDARY_COLOR } from '../mutations.type'
import ApiService from '@/services/api.service'
const actions = {
  [UPDATE_SELECTED_PLAYERS_FOR_PRINT](context, payload) {
    context.commit(SET_SELECTED_PLAYERS_FOR_PRINT, payload)
  },
  [UPDATE_ENABLED_BLEED_DOTS](context, payload) {
    context.commit(SET_ENABLED_BLEED_DOTS, payload)
  },
  async [FETCH_IMAGE_WITHOUT_CORS](context, payload) {
    await ApiService.get(`images/${payload.img_url}`)
      .then((response) => {
        context.commit(SET_IMAGE_WITHOUT_CORS, response)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  [UPDATE_PRINTING_ACTIVE_COLOR](context, payload) {
    context.commit(SET_PRINTING_ACTIVE_COLOR, payload)
  },
  [UPDATE_PRINTING_MAIN_COLOR](context, payload) {
    context.commit(SET_PRINTING_MAIN_COLOR, payload)
  },
  [UPDATE_PRINTING_SECONDARY_COLOR](context, payload) {
    context.commit(SET_PRINTING_SECONDARY_COLOR, payload)
  },
  [UPDATE_PRINTING_LOGO](context, payload) {
    context.commit(SET_PRINTING_LOGO_IMAGE, payload)
  }
}

export default actions
