const getters = {
  getSelectedPlayersForPrinting: (state) => state.selectedPlayersForPrinting,
  getEnabledBleedDots: (state) => state.enabledBleedDots,
  getImageWithoutCors: (state) => state.imageWithoutCors,
  getPrintingActiveColor: (state) => state.printing_active_color,
  getPrintingMainColor: (state) => state.printing_main_color,
  getPrintingSecondaryColor: (state) => state.printing_secondary_color,
  getPrintingLogoImage: (state) => state.printing_logo_image
}

export default getters
