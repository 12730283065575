import ApiService from '@/services/api.service'
import permissionServices from '@/services/permission.service'

import {
  SELECT_ORGANISATION,
  FETCH_ORGANISATIONS,
  FETCH_ORGANISATION_BY_ID,
  FETCH_ALL_ORG_USERS,
  FETCH_UNASSIGNED_BY_ORG,
  FETCH_USERS_BY_ORG,
  FETCH_PLAYERS_BY_ORG,
  FETCH_TRAINERS_BY_ORG,
  FETCH_ADMINS_BY_ORG,
  ADD_ORGANISATION,
  UPDATE_ORGANISATION,
  DELETE_ORGANISATION,
  UPDATE_ORG_ADMIN,
  ADD_USER_TO_ORG,
  FETCH_AND_STORE_TEAMS_BY_ORG,
  ADD_ORGANISATION_WITH_LICENSE,
  ASSIGN_LICENSE,
  FETCH_CUSTOM_THEME,
  FETCH_TRAINER_TEAMS
} from '../actions.type'

import {
  SET_ERROR,
  SET_ORGANISATIONS,
  SET_SELECTED_ORGANISATION,
  SET_ORG_UNASSIGNED,
  SET_ORG_USERS,
  SET_ORG_PLAYERS,
  SET_ORG_TRAINERS,
  SET_ORG_ADMINS,
  ADD_NEW_ORGANISATION,
  SET_UPDATED_ORGANISATION,
  REMOVE_ORGANISATION,
  ADD_USER_TO_UNASSIGNED
} from '../mutations.type'

const API_PREFIX_ORG = 'organisations'

const actions = {
  async [SELECT_ORGANISATION](context, orgId) {
    /**
     * For selecting an organisation in the top drop-down menu selection.
     * After an organisation is selected, all its corresponding data needs
     * to be refetched again and saved in the Vuex store.
     */

    context.commit(SET_SELECTED_ORGANISATION, orgId)

    await context.dispatch(FETCH_CUSTOM_THEME, orgId)

    if (permissionServices.hasOrganizationAdminRoles()) {
      await context.dispatch(FETCH_AND_STORE_TEAMS_BY_ORG)
      await context.dispatch(FETCH_ALL_ORG_USERS)
      return
    }
    if (permissionServices.hasTrainerRoles()) {
      await context.dispatch(FETCH_TRAINER_TEAMS)
    }
  },

  async [FETCH_ORGANISATIONS](context) {
    /**
     * Fetches all organisations and store the results in Vuex store
     */
    await ApiService.get(`/organisations`)
      .then(({ data }) => {
        context.commit(SET_ORGANISATIONS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_ORGANISATION_BY_ID](context, orgId) {
    /**
     * Fetches an organisation given its ID and store the results in Vuex store
     */
    await ApiService.get(`/organisations/${orgId}`)
      .then(({ data }) => {
        context.commit(SET_ORGANISATIONS, [data.data])
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_ALL_ORG_USERS](context) {
    /**
     * Fetches all different types of users for the selected organisation and stores the results in Vuex store
     */
    // Todo: Only do when user has at least organisation admin rights
    context.dispatch(FETCH_UNASSIGNED_BY_ORG)
    context.dispatch(FETCH_USERS_BY_ORG)
    context.dispatch(FETCH_PLAYERS_BY_ORG)
    context.dispatch(FETCH_TRAINERS_BY_ORG)
    context.dispatch(FETCH_ADMINS_BY_ORG)
  },

  async [FETCH_UNASSIGNED_BY_ORG](context) {
    /**
     * Fetch users in the currently selected organisation who
     * haven't been assigned to a team and save the results in Vuex store.
     */
    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.get(`/${API_PREFIX_ORG}/${org_id}/users/unassigned`)
      .then(({ data }) => {
        context.commit(SET_ORG_UNASSIGNED, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_USERS_BY_ORG](context) {
    /**
     * Fetch users in the currently selected organisation and
     * save the results in Vuex store.
     */
    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.get(`/${API_PREFIX_ORG}/${org_id}/users`)
      .then(({ data }) => {
        context.commit(SET_ORG_USERS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_PLAYERS_BY_ORG](context) {
    /**
     * Fetch players in the currently selected organisation and
     * save the results in Vuex store.
     */
    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.get(`/${API_PREFIX_ORG}/${org_id}/users/players`)
      .then(({ data }) => {
        context.commit(SET_ORG_PLAYERS, data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_TRAINERS_BY_ORG](context) {
    /**
     * Fetch trainers in the currently selected organisation and
     * save the results in Vuex store.
     */
    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.get(`/${API_PREFIX_ORG}/${org_id}/users/trainers`)
      .then(({ data }) => {
        context.commit(SET_ORG_TRAINERS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [FETCH_ADMINS_BY_ORG](context) {
    /**
     * Fetch admins in the currently selected organisation and
     * save the results in Vuex store.
     */
    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.get(`/${API_PREFIX_ORG}/${org_id}/users/orgadmins`)
      .then(({ data }) => {
        context.commit(SET_ORG_ADMINS, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [ADD_ORGANISATION](context, payload) {
    /**
     * Add a new organisation
     */

    await ApiService.post(`organisations`, payload)
      .then(({ data }) => {
        context.commit(ADD_NEW_ORGANISATION, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [ADD_ORGANISATION_WITH_LICENSE](context, { org, license }) {
    /**
     * Add a new organisation
     * And create a license
     */

    await ApiService.post(`organisations`, org)
      .then(({ data }) => {
        context.commit(ADD_NEW_ORGANISATION, data.data)

        // Add org id to payload and call assign license
        license.organisation_id = data.data.id
        context.dispatch(ASSIGN_LICENSE, license)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [UPDATE_ORGANISATION](context, payload) {
    /**
     * Update an existing organisation
     */

    await ApiService.patch(`/${API_PREFIX_ORG}/${payload.id}`, payload)
      .then(({ data }) => {
        context.commit(SET_UPDATED_ORGANISATION, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [DELETE_ORGANISATION](context, payload) {
    /**
     * Delete an organisation
     */
    await ApiService.delete(`/${API_PREFIX_ORG}/${payload.id}`)
      .then(() => {
        context.commit(REMOVE_ORGANISATION, payload)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [UPDATE_ORG_ADMIN](context, payload) {
    /**
     * Change a user's organisation admin role
     */

    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.post(
      `/${API_PREFIX_ORG}/${org_id}/users/${payload.user_id}`,
      payload
    )
      .then(() => {
        // Optimization: Don't fetch all data every time
        context.dispatch(FETCH_ALL_ORG_USERS)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  },

  async [ADD_USER_TO_ORG](context, payload) {
    /**
     * Add user to organisation without a role
     */

    const org_id = context.getters.getSelectedOrganisation.id

    await ApiService.post(`/${API_PREFIX_ORG}/${org_id}/users/`, payload)
      .then(({ data }) => {
        context.commit(ADD_USER_TO_UNASSIGNED, data.data)
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message)
      })
  }
}

export default actions
